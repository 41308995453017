import * as React from "react";
import {useEffect, useState} from "react";
import Layout from "../../components/Navigation/layout";
import {Client, handleApiError} from "../../services/ApiService";

const IndexPage = () => {
    const [datenschutz, setDatenschutz] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setDatenschutz(res.data[0].Datenschutz)
        }).catch(handleApiError)
    })

    return (
        <Layout pageTitle="Datenschutz">
            <div className={"container py-5"}>
                <h2>Datenschutz</h2>
                <div dangerouslySetInnerHTML={{__html: datenschutz}}/>
            </div>
        </Layout>
    )
}

export default IndexPage;
